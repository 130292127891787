import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import Logo from '../common/icons/Logo';
import * as styles from './index.styles';
import PoweredByLogo from "@/components/common/icons/PoweredByLogo";

const Footer = () => {
  const { t } = useTranslation();

  const showFooter = true;

  return showFooter ? (
    <styles.Container >
      <styles.LogoBlock>
        <HashLink to="/#">
          <Logo />
        </HashLink>
        <p>
          <a href="https://jobshash.com/" target="_blank" rel="noopener noreferrer">
          <PoweredByLogo />
          </a>
        </p>
        <p>{t('footer.rights')}</p>

        <styles.Separator />
      </styles.LogoBlock>
      <styles.ToolsBlock>
        <Link to="/terms-of-service">{t('footer.terms')}</Link>
        <Link to="/privacy-policy">{t('footer.policy')}</Link>
        <Link to="/cookie-policy">{t('footer.cookie')}</Link>
      </styles.ToolsBlock>
    </styles.Container>
  ) : null;
};

export default Footer;
