import styled from 'styled-components';
import { ThemeProps } from '@/styles/globalStyles';

interface IProps {
  geo?: boolean;
  isBundles?: boolean;
}

export const HeaderCardwrapper = styled.div`
  width: 100%;
  & a {
    display: flex;
    align-items: center;
    & span {
      color: ${(props: ThemeProps) => props.theme.blue};
      margin-left: 0.4rem;
      @media (max-width: 48rem) {
        margin-left: 0.2rem;
      }
    }
  }
`;

export const HeaderBlock = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
`;

export const IconBlock = styled.div`
  max-width: 1.5rem;
  max-height: 1.5rem;
  margin-right: 0.75rem;
  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const Name = styled.div`
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.875rem;
  color: ${(props: ThemeProps) => props.theme.deepDarkBlue};
`;

export const SubheaderBlock = styled.div`
  display: flex;
  margin-top: 0.5rem;
  align-items: center;
`;

export const BundleSubheaderBlock = styled.div`
  display: flex;
  margin-top: 0.75rem;
  align-items: center;
  @media (max-width: 20.625rem) {
    flex-direction: column;
    gap: 0.5rem;
    align-items: flex-start;
  }
`;

export const Separator = styled.div<IProps>`
  width: 0.375rem;
  height: 0.375rem;
  background-color: ${(props: ThemeProps) => props.theme.stroke};
  margin: 0 0.75rem;
  border-radius: 0.2rem;
  @media (max-width: 20.625rem) {
    display: ${(props) => (props.isBundles ? `none` : `block`)};
  }
`;

export const DescrBlock = styled.div`
  margin-top: 1rem;
`;

export const VisitLink = styled.a`
  position: absolute;
  right: -1.5rem;
  top: -0.5rem;
`;
