import React from 'react';
import { useEffect } from 'react';

const useOnClickOutside = (
  ref: React.RefObject<any>,
  isOpen: boolean,
  handler: (event: any) => void,
) => {
  return useEffect(() => {
    if(!isOpen) return;
    const listener = (event: any) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener(`mousedown`, listener);
    document.addEventListener(`touchstart`, listener);
    return () => {
      document.removeEventListener(`mousedown`, listener);
      document.removeEventListener(`touchstart`, listener);
    };
  }, [ref, handler, isOpen]);
};

export default useOnClickOutside;
