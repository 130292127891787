import styled from 'styled-components';

export const EmptyBlock = styled.div`
  margin-top: 3rem;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  @media (max-width: 48rem) {
    font-size: 1rem;
    margin-top: 2rem;
  }
`;
