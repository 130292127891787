import DropdownArrow from '@/components/common/icons/DropdownArrow';
import useOnClickOutside from '@/hooks/useOnClickOutside';
import { ChangeEvent, memo, useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import SearchBasic from '@/components/SearchBasic';
import * as styles from './index.styles';

interface Props {
  optionsTitle?: string;
  secondOptionsTitle?: string;
  options: { [key: string]: string };
  secondOptions?: { [key: string]: string };
  paramName: 'type' | 'tags' | 'geo';
  name: 'Type' | 'Technology' | 'Location';
  isSearchable?: boolean;
}

const Dropdown = ({
  options,
  secondOptions,
  optionsTitle,
  secondOptionsTitle,
  paramName,
  name,
  isSearchable,
}: Props) => {
  const [values, setValues] = useState(Object.keys(options));
  const [secondValues, setSecondValues] = useState(
    Object.keys(secondOptions || {}),
  );
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const [searchParams, setSearchParams] = useSearchParams();
  const [selected, setSelected] = useState<string[]>([]);
  const param = searchParams.get(paramName);
  const [search, setSearch] = useState('');

  useEffect(() => {
    if (param) {
      const filtered = param.split(',').filter((el) => values.includes(el));
      const secondFiltered = param
        .split(',')
        .filter((el) => secondValues.includes(el));
      setSelected([...filtered, ...secondFiltered]);
    }
    return () => setSelected([]);
  }, [param, searchParams, values, secondValues]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;

    if (selected.includes(name)) {
      setSelected((selected) => selected.filter((el) => el !== name));
    } else {
      setSelected((selected) => [...selected, name]);
    }
  };

  const handleClick = () => {
    setIsOpen((prevState) => !prevState);
  };

  const filteredValues = useMemo(() => {
    return Object.keys(options).filter((el) =>
      el.toLowerCase().includes(search.toLowerCase()),
    );
  }, [options, search]);

  const secondFilteredValues = useMemo(() => {
    return Object.keys(secondOptions || {}).filter((el) =>
      el.toLowerCase().includes(search.toLowerCase()),
    );
  }, [secondOptions, search]);

  const apply = () => {
    if (selected.length) {
      searchParams.set(paramName, selected.join(','));
    } else {
      searchParams.delete(paramName);
    }
    setSearchParams(searchParams);
    setIsOpen(false);
  };

  useEffect(() => {
    if (!isOpen) {
      setValues(
        filteredValues.sort(
          (a, b) => selected.indexOf(b) - selected.indexOf(a),
        ),
      );
      setSecondValues(
        secondFilteredValues.sort(
          (a, b) => selected.indexOf(b) - selected.indexOf(a),
        ),
      );
    }
  }, [isOpen, filteredValues, secondFilteredValues, selected]);

  useOnClickOutside(wrapperRef, isOpen, apply);

  return (
    <styles.DropdownContainer ref={wrapperRef}>
      {isOpen && isSearchable ? (
        <styles.SearchWrapper>
          <SearchBasic
            value={search}
            isSearchable
            placeholder={
              name === 'Technology' ? `Search technologies` : `Search Locations`
            }
            onChange={(e) => setSearch(e.target.value)}
          />
        </styles.SearchWrapper>
      ) : (
        <styles.DropdownButton isOpen={isOpen} onClick={handleClick}>
          {!isOpen && selected.length ? (
            <p>
              {name}:<span>{selected.length} selected </span>
            </p>
          ) : (
            <p>{name}</p>
          )}
          <DropdownArrow />
        </styles.DropdownButton>
      )}
      <styles.DropdownMenu isSearchable={isSearchable} isOpen={isOpen}>
        <div>
          {!!filteredValues.length && optionsTitle && (
            <styles.Title>{optionsTitle}</styles.Title>
          )}
          {filteredValues.map((el) => (
            <li key={el}>
              <input
                checked={selected.includes(el)}
                name={el}
                onChange={(e) => handleChange(e)}
                type="checkbox"
                id={el}
              />
              {/* @ts-ignore */}
              <label htmlFor={el}>{options[el]}</label>
            </li>
          ))}
          {!!secondFilteredValues.length && secondOptionsTitle && (
            <styles.Title>{secondOptionsTitle}</styles.Title>
          )}
          {secondFilteredValues.map((el) => (
            <li key={el}>
              <input
                checked={selected.includes(el)}
                name={el}
                onChange={(e) => handleChange(e)}
                type="checkbox"
                id={el}
              />
              {/* @ts-ignore */}
              <label htmlFor={el}>{secondOptions[el]}</label>
            </li>
          ))}
        </div>
        {!secondFilteredValues.length && !filteredValues.length && (
          <styles.EmptyBlock>Parameters not found :(</styles.EmptyBlock>
        )}
        <li>
          <styles.FooterSelect>
            <div>
              <span>{selected.length}</span>
              selected
            </div>
            <styles.ApplyButton onClick={apply}>Apply</styles.ApplyButton>
          </styles.FooterSelect>
        </li>
        <styles.Separator />
      </styles.DropdownMenu>
    </styles.DropdownContainer>
  );
};

export default memo(Dropdown);
