import { LoaderContext } from '@/context';
import { ReactNode, Suspense, useContext, useEffect } from 'react';

const Loader = () => {
  const { startLoader, stopLoader } = useContext(LoaderContext);

  useEffect(() => {
    startLoader();
    return () => {
      stopLoader();
    };
  }, [startLoader, stopLoader]);

  return null;
};

interface Props {
  children: ReactNode;
}

const FallbackLoader = ({ children }: Props) => (
  <Suspense fallback={<Loader />}>{children}</Suspense>
);

export default FallbackLoader;
