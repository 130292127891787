import styled from 'styled-components';
import { ThemeProps } from '@/styles/globalStyles';

export const CardBlock = styled.div`
  box-sizing: border-box;
  padding: 1.5rem;
  border: 0.050rem solid ${(props: ThemeProps) => props.theme.blue};
  width: 100%;
  background: ${(props: ThemeProps) => props.theme.white};
  border-radius: 0.9375rem;
  @media (max-width: 48rem) {
    padding: 1rem;
  }
  @media (max-width: 45.62rem) {
    margin: 0;
    margin-bottom: 1rem;
  }
`;

export const CardContent = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  & span {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.3125;
    color: ${(props: ThemeProps) => props.theme.grey};
  }
`;
