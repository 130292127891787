import styled from 'styled-components';
import { ThemeProps } from '@/styles/globalStyles';

export const Paragraph = styled.p`
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1.875rem;
  color: ${(props: ThemeProps) => props.theme.grey};
  margin-top: 2rem;
  @media (max-width: 48rem) {
    font-size: 1rem;
    line-height: 1.5rem;
    margin-top: 1.5rem;
  }
`;
