import { ISearchParams } from '@/utils/types';
import { landingPages } from './constants';

export const showCurrencySymbol = (currency: string) => {
  switch (currency) {
    case 'EUR':
      return '€';
    case 'PLN':
      return 'zł';
    case 'GBP':
      return '£';
    default:
      return '$';
  }
};

export const showPeriod = (period: string) => {
  switch (period) {
    case 'HOURLY':
      return 'hour';
    case 'WEEKLY':
      return 'week';
    case 'YEARLY':
      return 'year';
    default:
      return 'month';
  }
};

export const disableScrolling = (value: boolean) => {
  if (!value) {
    document.body.style.overflowY = 'auto';
  } else {
    document.body.style.overflowY = 'hidden';
  }
};

export const displayDate = (str: string) => {
  return new Date(str).toDateString();
};

export const openChat = (): void => {
  document
    .getElementById('woot-widget-bubble-icon')
    // @ts-ignore
    ?.parentNode?.click();
};

export const getNumberOfDays = (start: Date, end: Date = new Date()) => {
  return Math.round((end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24));
};

export const showPrice = (price: number, currency: string) => {
  const p = price !== 0 ? `${showCurrencySymbol(currency)}${price}` : 'Free';
  return p;
};

export const capitalizeFirstLetter = (str: string) =>
  str[0].toUpperCase() + str.slice(1).toLocaleLowerCase();

export const deleteUnderscoreAndMakeCapitalizeFirstLetters = (str: string) => {
  return str
    .replaceAll('_', ' ')
    .split(' ')
    .map((s) => capitalizeFirstLetter(s))
    .join(' ');
};

export const getShortNumberOfaudienc = (num: number) => {
  if (num > 1000000) {
    return `${Math.round(num / 1000000)}M`;
  } else if (num > 1000) {
    return `${Math.round(num / 1000)}K`;
  } else {
    return `${num}`;
  }
};

export const readSearchParams = (
  searchParams: URLSearchParams,
): ISearchParams => {
  const type = searchParams.get('type') || undefined;
  const name = searchParams.get('name') || undefined;
  const tags = searchParams.get('tags') || undefined;
  const geo = searchParams.get('geo') || undefined;
  const minPrice = searchParams.get('minPrice')
    ? Number(searchParams.get('minPrice'))
    : undefined;
  const maxPrice = searchParams.get('maxPrice')
    ? Number(searchParams.get('maxPrice'))
    : undefined;
  return {
    type,
    name,
    tags,
    geo,
    maxPrice,
    minPrice,
  };
};

export const mapErrorMessage = (str: string) => {
  switch (str) {
    case 'subscription.missing':
      return 'Your subscription has been expired, please upgrade subscription or contact us for any questions.';
    case 'publications.limit':
      return 'You requested more publications that are available in your current plan, please upgrade subscription or contact us for any questions.';
    default:
      return '';
  }
};

export const getParamsString = (searchParams: URLSearchParams) => {
  const { name, tags, minPrice, maxPrice, geo } =
    readSearchParams(searchParams);
  return `${
    (name ? `&name=${name}` : ``) +
    (tags ? `&tags=${tags}` : ``) +
    (geo ? `&geo=${geo}` : ``) +
    (minPrice ? `&minPrice=${minPrice}` : ``) +
    (maxPrice ? `&maxPrice=${maxPrice}` : ``)
  }`;
};

export const isFindPage = (path: string) => landingPages.includes(path);
