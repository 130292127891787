// import Button from '@/components/common/Button';
import EmptyBlock from '@/components/common/EmptyBlock';
import FilterPanel from '@/components/FilterPanel';
import { LoaderContext } from '@/context';
import { getAllChannels } from '@/services/api';
import { readSearchParams } from '@/utils/helpers';
import { IChannel } from '@/utils/types';
import { memo, useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import ChannelCard from '../ChannelCard';
import * as styles from './index.styles';
import Footer from "@/components/Footer";
interface IChannelProps {
  isFindDevelopersPage?: boolean;
  filtrationType?: string;
}

const ChannelsCards = ({
  isFindDevelopersPage,
  filtrationType,
}: IChannelProps) => {
  const { startLoader, stopLoader } = useContext(LoaderContext);
  const [allChannels, setAllChannels] = useState<IChannel[] | []>([]);
  const [wait, setWait] = useState(true);
  const [searchParams] = useSearchParams();
  const { name, type, tags, minPrice, maxPrice, geo } =
    readSearchParams(searchParams);

  useEffect(() => {
    const getChannels = async () => {
      try {
        startLoader();
        const response = await getAllChannels({
          name,
          geo,
          type: filtrationType || type,
          tags,
          minPrice,
          maxPrice,
        });
        if (response.status === 200) {
          setAllChannels(response.data);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setWait(false);
        stopLoader();
      }
    };
    getChannels();
  }, [name, geo, minPrice, tags, type, maxPrice, filtrationType]);

  return wait ? (
    <></>
  ) : (
    <styles.ChannelsCardsWrapper>
      <FilterPanel
        isFindDevelopersPage={isFindDevelopersPage}
        placeholder={'Search Channels'}
      />
      {
        allChannels.length ? (
          <styles.CardsBlock>
            {allChannels.map((item: IChannel) => (
              <ChannelCard
                key={item.id}
                data={item}
                filtrationType={filtrationType}
              />
            ))}
          </styles.CardsBlock>
        ) : (
          <EmptyBlock type="channels" />
        )
      }
      <Footer />
    </styles.ChannelsCardsWrapper>

  );
};
export default memo(ChannelsCards);
