import { memo } from 'react';

interface Props {
  onClick?: (e: React.SyntheticEvent) => void;
}

const DropdownArrow = ({ onClick }: Props) => (
  <svg
    width="18"
    height="9"
    viewBox="0 0 18 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
  >
    <path
      d="M16.9201 8.05014L10.4001 1.53014C9.63008 0.760137 8.37008 0.760137 7.60008 1.53014L1.08008 8.05014"
      stroke="#050A30"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default memo(DropdownArrow);
