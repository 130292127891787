import React, { useState } from 'react';
import * as styles from './index.styles';
import { MoreTags } from "./index.styles";

// @ts-ignore
const TagsComponent = ({data} : Props) => {
  const [showAll, setShowAll] = useState(false);

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  const displayedTags = showAll || data?.tags.length <= 5 ? data?.tags : data?.tags.slice(0, 4);

  return (
    <>
      <styles.TagsBlock>
        {displayedTags.map((tag: string) => (
          <styles.Tag key={tag}>{tag}</styles.Tag>
        ))}

        {data?.tags.length > 5 && (
          <MoreTags onClick={toggleShowAll} isExpanded={showAll}>
            {showAll ? `Less` : `+${data?.tags.length - 4} more`}
          </MoreTags>
        )}

      </styles.TagsBlock>

    </>
  );
}

export default TagsComponent;
