import {
  ISearchParams,
} from '@/utils/types';
import axios from 'axios';
const httpClient = axios.create({
  baseURL: process.env.REACT_APP_API_HOST + '/jobspush-service/api/v1',
  headers: {
    'Content-Type': 'application/json',
  },
});
httpClient.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);
httpClient.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    if (err.response) {
      if (err.response.data) {
        return Promise.reject(err.response.data);
      }
      if (err.response.status === 404) {
        window.location.href = '/404';
      }
      if (err.response.status > 499) {
        window.location.href = '/500';
      }
    }
    return Promise.reject(err);
  },
);


export const getAllTechnology = async () => {
  try {
    return httpClient.get('/tags');
  } catch (error) {
    console.log('Error happened while getting tags: ', error);
    throw error;
  }
};

export const getAllGeoTags = async () => {
  try {
    return httpClient.get('/geotags');
  } catch (error) {
    console.log('Error happened while getting tags: ', error);
    throw error;
  }
};

export const getAllChannels = async (params: ISearchParams) => {
  try {
    return httpClient.get('/channels', { params });
  } catch (error) {
    console.log('Error happened while getting channels: ', error);
    throw error;
  }
};

export const getAllBundles = async (params: ISearchParams) => {
  try {
    return httpClient.get('/bundles', { params });
  } catch (error) {
    console.log('Error happened while getting bundles: ', error);
    throw error;
  }
};
