import styled from 'styled-components';
import { ThemeProps } from '@/styles/globalStyles';

export const H2 = styled.h2`
  font-weight: 700;
  font-size: 2.5rem;
  line-height: 3rem;
  color: ${(props: ThemeProps) => props.theme.deepDarkBlue};
  @media (max-width: 48rem) {
    font-size: 1.75rem;
    line-height: 2.25rem;
  }
  @media (max-width: 37.5rem) {
    font-size: 1.5rem;
  }
`;
