import styled from 'styled-components';
import { ThemeProps } from '@/styles/globalStyles';

export const CardBlock = styled.div`
  box-sizing: border-box;
  padding: 1.5rem;
  width: 100%;
  background: ${(props: ThemeProps) => props.theme.white};
  border-radius: 0.9375rem;
  @media (max-width: 48rem) {
    padding: 1rem;
  }
  @media (max-width: 45.62rem) {
    margin: 0;
    margin-bottom: 1rem;
  }
`;

export const CardContent = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  & span {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.3125;
    color: ${(props: ThemeProps) => props.theme.grey};
  }
`;

export const PriceBlock = styled.div`
  display: flex;
  margin-top: 0.75rem;
  justify-content: space-between;
  align-items: center;
`;

export const ButtonWrapper = styled.div`
  padding: 0 2rem 0;
  max-width: 6.125rem;
  height: 3rem;
  & button {
    & span {
      font-weight: 700;
      font-size: 1rem;
      line-height: 1rem;
      color: ${(props: ThemeProps) => props.theme.white};
    }
  }
  @media (max-width: 48rem) {
    padding: 0;
    padding-top: 0.75rem;
  }
`;

export const Price = styled.div`
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5rem;
  margin-top: 0.25rem;
  color: ${(props: ThemeProps) => props.theme.blue};
`;

export const ProductDescription = styled.div`
  display: block;
  margin: 0.25rem 1rem 0 0;
  color: ${(props: ThemeProps) => props.theme.lightGrey};
  line-height: 1.125rem;
  font-size: 0.75rem;
  @media (max-width: 90rem) {
    margin: 0.25rem 4rem 0 0;
  }
`;
