import styled from 'styled-components';
import { ThemeProps } from '@/styles/globalStyles';

export const SearchWrapper = styled.div`
  width: 100%;
  min-width: 16rem;
  height: 2.75rem;
  position: relative;
  box-sizing: border-box;
  @media (max-width: 65rem) {
    max-width: 100%;
  }
  & svg {
    position: absolute;
    left: 1.125rem;
    top: 0.625rem;
  }
`;

export const SearchContainer = styled.input`
  width: 100%;
  height: 2.75rem;
  padding-left: 3.125rem;
  padding-right: 1rem;
  box-sizing: border-box;
  background-color: ${(props) => props.theme.white};
  border-radius: 0.5rem;
  font-weight: 400;
  font-size: 1rem;
  color: ${(props: ThemeProps) => props.theme.grey};
  line-height: 1.5rem;
  border: 0.0625rem solid ${(props: ThemeProps) => props.theme.stroke};
  outline: none;
  &:focus-visible {
    border-color: ${(props: ThemeProps) => props.theme.blue};
  }
`;
