import { ChangeEvent, memo, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import SearchBasic from '@/components/SearchBasic';
import { readSearchParams } from '@/utils/helpers';

interface Props {
  value?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  isBundles?: boolean;
}

const Search = ({ onChange, placeholder }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { name } = readSearchParams(searchParams);
  const [currentName, setCurrentName] = useState(name || '');
  const [touched, setTouched] = useState(false);

  useEffect(() => {
    if (name) {
      setCurrentName(name);
    }
    return () => {
      setCurrentName('');
      setTouched(false);
    };
  }, [name]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCurrentName(e.target.value);
  };

  useEffect(() => {
    if (touched) {
      const timer = setTimeout(() => {
        if (currentName) {
          searchParams.set('name', currentName);
        } else {
          searchParams.delete('name');
        }
        setSearchParams(searchParams);
      }, 600);

      return () => clearInterval(timer);
    }
  }, [currentName, setSearchParams]);

  const onLocalChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTouched(true);
    if (onChange) {
      onChange(e);
    } else if (handleChange) {
      handleChange(e);
    }
  };

  return (
    <SearchBasic
      value={currentName}
      onChange={onLocalChange}
      placeholder={placeholder}
    />
  );
};

export default memo(Search);
