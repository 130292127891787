import { memo } from 'react';
import * as styles from './index.styled';
import { IChannel } from '@/utils/types';
import CardBlock from '@/components/Card/CardBlock';

interface Props {
  data: IChannel;
  filtrationType?: string;
}

const ChannelCard = ({ data }: Props) => {

  return (
    <CardBlock data={data}>
      <styles.PriceBlock>
        <div>

        </div>
      </styles.PriceBlock>
    </CardBlock>
  );
};

export default memo(ChannelCard);
