import { memo, ReactElement } from 'react';
import * as styles from './index.styled';
import HeaderCard from '@/components/Card/HeaderCard/index';
import { IBundle, IChannel } from '@/utils/types';

interface IProps {
  children: ReactElement | null | string;
  data?: IChannel | IBundle;
  isBundles?: boolean;
}
const CardBlock = ({ data, isBundles, children }: IProps) => {
  return (
    <styles.CardBlock className="CardBlock">
      <styles.CardContent>
        {data && <HeaderCard data={data} isBundles={isBundles} />}
        {children}
      </styles.CardContent>
    </styles.CardBlock>
  );
};

export default memo(CardBlock);
