import styled from 'styled-components';

export const FilterPanel = styled.div`
  width: 100%;
  display: grid;
  margin: 0 0 2rem;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1rem;
  @media (max-width: 93rem) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  @media (max-width: 76.25rem) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 65rem) {
    grid-template-columns: 1fr;
  }
  @media (max-width: 36.25rem) {
    grid-template-columns: 1fr;
  }
`;
