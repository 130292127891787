import GeoDropdown from '@/components/FilterPanel/components/GeoDropdown';
import TechnologyDropdown from '@/components/FilterPanel/components/TechnologyDropdown';
import TypesDropdown from '@/components/FilterPanel/components/TypesDropdown';
import { memo } from 'react';
import Search from './components/Search';
import * as styles from './index.styled';

interface IProps {
  isBundles?: boolean;
  placeholder?: string;
  isFindDevelopersPage?: boolean;
}
const FilterPanel = ({
  isBundles,
  placeholder,
}: IProps) => {

  return (
    <styles.FilterPanel>
      <Search isBundles={isBundles} placeholder={placeholder} />
      <TechnologyDropdown />
      <GeoDropdown />
      <TypesDropdown />
      {/*<PriceDropdown />*/}

    </styles.FilterPanel>
  );
};
export default memo(FilterPanel);
