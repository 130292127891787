import styled from 'styled-components';
import { ThemeProps } from '@/styles/globalStyles';

export const ChannelsCardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.25rem 5.5rem 5.5rem 5.5rem;
  @media (max-width: 90rem) {
    padding: 0.25rem 1rem 5.5rem 1rem;
  }
  @media (max-width: 65rem) {
    padding: 0.25rem 1rem 5.5rem 1rem;
  }
`;

export const CardsBlock = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
  justify-content: space-between;
  @media (max-width: 90rem) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }
  @media (max-width: 65rem) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    gap: 1.25rem;
  }
  @media (max-width: 48rem) {
    gap: 1rem;
  }
`;

export const PublishChannelsCardBlock = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  // add blue border
  
  gap: 1.5rem;
  align-content: stretch;
  @media (max-width: 65rem) {
    grid-template-columns: 1fr;
    gap: 1.25rem;
  }
  @media (max-width: 48rem) {
    gap: 1rem;
  }
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  max-height: 3rem;
  height: 100%;
  margin: 1.5rem 0 1.5rem 0;
  & button {
    width: 100%;
    height: 100%;
    background-color: #ecf1fd;
    color: ${(props: ThemeProps) => props.theme.blue};
    &:hover {
      transform: scale(1.02);
    }
  }
  @media (max-width: 48rem) {
    margin-top: 1rem;
  }
`;
