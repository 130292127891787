import * as styles from './index.styles';

interface IEmptyBlockProps {
  type: string;
}
const EmptyBlock = ({ type }: IEmptyBlockProps) => {
  return (
    <styles.EmptyBlock>
      {`There are no currently ${type} yet :(`}
    </styles.EmptyBlock>
  );
};

export default EmptyBlock;
