const colors = {
  white: '#FFFFFF',
  blue: '#2685F0',
  darkBlue: '#132880',
  deepDarkBlue: '#050A30',
  grey: '#696C83',
  background: '#fbfcff',
  secondaryBackground: '#F4F7FE',
  stroke: '#ECECEC',
  lightGrey: '#9F9F9F',
  error: '#ff8181',
  turquoise: '#65ACF0',
  light: '#edf8fc',
  lightBlue: '#3b91f1',
  black: '#000000',
  lightViolet: '#A3AED0',
  footerBackground: '#FBFCFF',
  green: '#4CAF50',
  yellow: '#FFCA42',
  lightGreen: '#D6EEEE',
  softBlue: '#E5F2FB',
};
export const lightTheme = {
  ...colors,
};

export const darkTheme = {
  ...lightTheme,
  background: colors.deepDarkBlue,
  deepDarkBlue: colors.white,
};

export type Theme = typeof lightTheme;
