import { memo } from 'react';

interface Props {
  onClick?: (e: React.SyntheticEvent) => void;
}

const PoweredByLogo = ({ onClick }: Props) => (
  <svg
    width="126"
    height="24"
    viewBox="0 0 126 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
  >
    <text
      x="0"
      y="20"
      fill="#2685ef"
      fontFamily="'Arial'"
      fontSize="10"
      fontWeight="bold">
      powered by
    </text>
    <text
      x="61"
      y="20"
      fill="#2685ef"
      fontFamily="'Arial', sans-serif"
      fontSize="15"
      fontWeight="bold">
      JobsHash
    </text>
  </svg>
);

export default memo(PoweredByLogo);
