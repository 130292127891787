import { ThemeProps } from '@/styles/globalStyles';
import styled from 'styled-components';
import { H2 } from '@/components/common/H2/index.styles';
import { Paragraph } from '@/components/common/Paragraph/index.styles';

interface IProps {
  isFindDevelopersPage?: boolean;
}
export const Container = styled.div<IProps>`
  background-color: ${(props) => props.theme.background};
  padding-top: 4.5rem;
  padding-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  @media (max-width: 62rem) {
    padding-left: ${(props: IProps) => !props.isFindDevelopersPage && `2rem`};
    padding-right: ${(props: IProps) => !props.isFindDevelopersPage && `2rem`};
  }
  @media (max-width: 48rem) {
    flex-direction: column;
    //padding-top: 4.5rem;
    //padding-bottom: 4rem;
  }
  @media (max-width: 37.5rem) {
    padding-left: ${(props: IProps) =>
      !props.isFindDevelopersPage && `1.25rem`};
    padding-right: ${(props: IProps) =>
      !props.isFindDevelopersPage && `1.25rem`};
  }
`;

export const Content = styled.div`
  max-width: 80rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  @media (max-width: 48rem) {
    max-width: none;
    width: 100%;
  }
`;

export const InfoBlock = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

export const Title = styled(H2)`
  text-align: center;
  max-width: 52.5rem;
  @media (max-width: 48rem) {
  }
  & span {
    color: ${(props: ThemeProps) => props.theme.blue};
  }
`;

export const Span = styled.span`
  color: ${(props: ThemeProps) => props.theme.blue};
`;

export const Info = styled(Paragraph)`
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.275;
  text-align: center;
  max-width: ${(props: IProps) => !props.isFindDevelopersPage && `52.5rem`};
  @media (max-width: 48rem) {
    margin-top: 1.5rem;
    font-size: ${(props: IProps) =>
      props.isFindDevelopersPage ? `0.875rem` : `1rem`};
    line-height: 1.5;
  }
`;

export const Separator = styled.div`
  height: ${(props: IProps) =>
    props.isFindDevelopersPage ? `7rem` : `9.4375rem`};
  margin: 0 2.5rem;
  border: 0.063rem solid;
  color: ${(props: ThemeProps) => props.theme.stroke};
  &:last-child {
    display: none;
  }
  @media (max-width: 90rem) {
    display: none;
  }
`;

export const CardsBlock = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  width: 30%;
  box-sizing: border-box;
  @media (max-width: 90rem) {
    gap: 3rem;
    padding: 0 1rem;
  }
  @media (max-width: 48rem) {
    padding: 0 1rem;
    margin-top: 2.5rem;
  }
`;

export const Card = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  align-items: center;
  min-width: 8.3125rem;
  max-width: 8.3251rem;
  @media (max-width: 86.25rem) {
    min-width: 8rem;
  }
  @media (max-width: 48rem) {
    min-width: 3rem;
  }
`;

export const CardTitle = styled(H2)`
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.1875rem;
  color: ${(props: ThemeProps) => props.theme.blue};
  @media (max-width: 48rem) {
    font-size: 2rem;
    line-height: 2.6875rem;
  }
`;

export const CardSubtitle = styled.span`
  font-weight: 500;
  margin-top: ${(props: IProps) =>
    props.isFindDevelopersPage ? `0.2rem` : `1.5rem`};
  font-size: ${(props: IProps) =>
    props.isFindDevelopersPage ? `1rem` : `1.1rem`};
  line-height: 150%;
  text-align: center;
  color: ${(props: ThemeProps) => props.theme.grey};
  @media (max-width: 90rem) {
    min-height: 80px;
  }
  @media (max-width: 48rem) {
    font-size: ${(props: IProps) =>
      props.isFindDevelopersPage ? `0.875rem` : `1rem`};
    line-height: 1.5rem;
  }
`;
