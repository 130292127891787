import { memo } from 'react';
import Dropdown from '../Dropdown';

const types = {
  JOB_BOARD: 'Job Board',
  TELEGRAM: 'Telegram',
  REDDIT: 'Reddit',
  SLACK: 'Slack',
  DISCORD: 'Discord',
  NEWSLETTER: 'Newsletter',
};

const TypesDropdown = () => {
  return <Dropdown options={types} isSearchable name="Type" paramName="type" />;
};

export default memo(TypesDropdown);
