import {
  addCartItem,
  clearCart,
  getCartItems,
  removeCartItem,
} from '@/services/cartStorage';
import { darkTheme, lightTheme, Theme } from '@/styles/theme';
import { createContext, useState } from 'react';
import { ICartItem } from '../utils/types';

export const LoaderContext = createContext({
  startLoader: () => {},
  stopLoader: () => {},
});

export const ThemeContext = createContext({
  theme: localStorage.getItem('darkTheme') ? darkTheme : lightTheme,
  setTheme: (theme: Theme) => {},
});

interface ICartContext {
  items: ICartItem[];
  addItem: (item: ICartItem) => void;
  removeItem: (index: number) => void;
  clearCart: () => void;
}

export const CartContext = createContext<ICartContext>({
  items: [],
  addItem: () => {},
  removeItem: () => {},
  clearCart: () => {},
});
export const CartContextProvider = ({ ...rest }) => {
  const [items, setItems] = useState<ICartItem[]>(getCartItems());

  return (
    <CartContext.Provider
      value={{
        items,
        addItem: (item) => {
          setItems((old) => [...old, item]);
          addCartItem(item);
        },
        removeItem: (index) => {
          setItems((old) => {
            old.splice(index, 1);
            return [...old];
          });
          removeCartItem(index);
        },
        clearCart: () => {
          setItems([]);
          clearCart();
        },
      }}
      {...rest}
    />
  );
};

export const PostAJobContext = createContext<any>(null);

export const DeviceContext = createContext<any>(null);
