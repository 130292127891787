import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

i18n.on('languageChanged', (lng) => {
  document.documentElement.lang = lng;
});

export const languages = {
  en: 'en',
  // ru: 'ru',
};

// const browserLang = localStorage.getItem('i18nextLng');

i18n
  .use(detector)
  .use(backend)
  .use(initReactI18next)
  .init({
    fallbackLng: languages.en,
    interpolation: {
      escapeValue: false,
    },
    supportedLngs: [...Object.values(languages)],
  });

export default i18n;
