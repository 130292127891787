import { getAllTechnology } from '@/services/api';
import { memo, useEffect, useState } from 'react';
import Dropdown from '../Dropdown';

type ITags = { [key: string]: string };

const TechnologyDropdown = () => {
  const [allTagList, setAllTagList] = useState<ITags>({});

  const getTags = async () => {
    try {
      const response = await getAllTechnology();
      if (response.status === 200) {
        const tags: ITags = {};
        response.data.forEach((el: any) => {
          tags[el.name] = el.name;
        });
        setAllTagList(tags);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getTags();
  }, []);

  return !!Object.keys(allTagList).length ? (
    <Dropdown
      options={allTagList}
      paramName="tags"
      name="Technology"
      isSearchable
    />
  ) : null;
};

export default memo(TechnologyDropdown);
