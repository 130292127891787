import { IBundle, IChannel } from '@/utils/types';
import { memo } from 'react';
import * as styles from './index.styles';
import {
  deleteUnderscoreAndMakeCapitalizeFirstLetters,
  getShortNumberOfaudienc,
} from '@/utils/helpers';
import ArrowRight from '@/components/common/icons/ArrowRight';
import TagsComponent from "@/components/Card/TagsComoponent";

interface Props {
  data: IChannel | IBundle;
  isBundles?: boolean;
}

const HeaderCard = ({ data, isBundles }: Props) => {
  return (
    <styles.HeaderCardwrapper>
      <>

        <a rel="noreferrer" target="_blank" href={(data as IChannel).url}>
          <styles.HeaderBlock>
            <styles.IconBlock>
              <img
                loading="lazy"
                width="100%"
                height="100%"
                src={
                  data?.logoUrl
                    ? data.logoUrl
                    : `/images/default_job_placeholder.png`
                }
                alt={data?.name + `-icon`}
              />
            </styles.IconBlock>
            <styles.Name>
              {data?.name}
              {!isBundles && (
                <span>
                  <ArrowRight />
                </span>
              )}
            </styles.Name>
          </styles.HeaderBlock>
        </a>

          <styles.SubheaderBlock>
            <span>
              {deleteUnderscoreAndMakeCapitalizeFirstLetters(
                (data as IChannel).channelType,
              )}
            </span>
            <styles.Separator />
            {(data as IChannel).channelType !== 'JOB_BOARD' ? (
              <span>{`${getShortNumberOfaudienc(
                data?.audience || 0,
              )}  subscribers`}</span>
            ) : (
              <span>{`${getShortNumberOfaudienc(
                data?.audience || 0,
              )}  views / month`}</span>
            )}
          </styles.SubheaderBlock>
        <TagsComponent data={data} />
        <styles.DescrBlock>
          <span>{data?.description}</span>
        </styles.DescrBlock>
      </>
    </styles.HeaderCardwrapper>
  );
};

export default memo(HeaderCard);
