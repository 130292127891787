import { ThemeProps } from '@/styles/globalStyles';
import styled from 'styled-components';

export const Container = styled.footer`
  overflow-x: hidden;
  padding-top: 4rem;
  padding-bottom: 4rem;
  padding-left: 1.5rem;
  display: flex;
  & > div {
    box-sizing: border-box;
    padding: 0 2.5rem;
    &:last-child {
      padding-right: 0;
    }
    &:first-child {
      padding-left: 0;
    }
  }
  @media (max-width: 65rem) {
    & > div {
      justify-content: flex-start;
      padding: 0 0.5rem;
      width: 100%;
      border: none;
    }
  }
  @media (max-width: 48rem) {
    padding-top: 2.5rem;
    padding-bottom: 0;
    flex-wrap: wrap;
    & > div {
      padding: 0 0 2.5rem 0;
    }
  }
`;

export const LogoBlock = styled.div`
  min-width: -moz-fit-content;
  min-width: fit-content;
  & p {
    margin-top: 1rem;
    color: ${(props: ThemeProps) => props.theme.grey};
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.313rem;
  }
  @media (max-width: 26rem) {
    position: relative;
  }
`;

export const ToolsBlock = styled.div`
  display: flex;
  flex-direction: column;
  min-width: -moz-fit-content;
  min-width: fit-content;
  & b {
    color: ${(props: ThemeProps) => props.theme.deepDarkBlue};
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: 1rem;
  }
  & a {
    color: ${(props: ThemeProps) => props.theme.deepDarkBlue};
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.313rem;
    padding: 0.5rem 0;
    width: -moz-fit-content;
    width: fit-content;
    &:first-child {
      padding-top: 0;
    }
    &:hover {
      transform: scale(1.05);
    }
  }
  @media (max-width: 48rem) {
    width: 50% !important;
    padding-right: 2.5rem !important;
  }
  @media (max-width: 26rem) {
    width: 100% !important;
  }
`;

export const AnchorsAndTermsBlock = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-width: 16.875rem;
  flex-wrap: wrap;
  @media (max-width: 48rem) {
    flex-direction: column;
    justify-content: center;
  }
  @media (max-width: 26rem) {
    width: 100%;
    position: relative;
  }
`;

export const AnchorsBlock = styled.div`
  & a {
    color: ${(props: ThemeProps) => props.theme.deepDarkBlue};
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5rem;
    display: inline-block;
    margin-bottom: 1rem;
    &:hover {
      transform: scale(1.05);
    }
    &:first-child {
      margin-right: 2.5rem;
    }
  }
`;

export const TermsBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: -moz-fit-content;
  min-width: fit-content;
  & a {
    color: ${(props: ThemeProps) => props.theme.deepDarkBlue};
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5rem;
    display: inline-block;
    margin-bottom: 1rem;
    &:hover {
      transform: scale(1.05);
      &:first-child {
        margin-left: 0;
      }
    }
  }
`;

export const JoinBlock = styled.div`
  border-left: 0.063px solid ${(props: ThemeProps) => props.theme.stroke};
  display: flex;
  flex-direction: column;
  min-width: -moz-fit-content;
  min-width: fit-content;

  & b {
    color: ${(props: ThemeProps) => props.theme.deepDarkBlue};
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.875rem;
    margin-bottom: 1.5rem;
  }

  & div {
    display: flex;
    margin-bottom: 0.75rem;
    @media (max-width: 70.625rem) {
      flex-direction: column;
      gap: 1.5rem;
    }
    @media (max-width: 48rem) {
      flex-direction: row;
      gap: 0;
    }

    & button {
      min-width: 8rem;

      &:first-child {
        margin-right: 1rem;
      }
    }
  }

  @media (max-width: 48rem) {
    align-items: center;
  }
`;

export const Separator = styled.div`
  display: none;
  position: absolute;
  left: -5rem;
  right: -5rem;
  top: 5rem;
  width: 40rem;
  height: 0.938rem;
  background-color: ${(props: ThemeProps) => props.theme.stroke};
  @media (max-width: 26rem) {
  height: 0.063rem;
    display: block;
  }
`;

export const SecondSeparator = styled(Separator)`
  top: 16rem;
`;
