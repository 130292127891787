import { useMemo } from 'react';
import { HashLink } from 'react-router-hash-link';
import Logo from '../common/icons/Logo';
import PoweredByLogo from '@/components/common/icons/PoweredByLogo';
import {
  Container,
} from './index.styles';
import { useLocation } from 'react-router-dom';
import { isFindPage } from '@/utils/helpers';

const Header = () => {
  const { pathname } = useLocation();

  const isFindDevelopersPage = useMemo(() => {
    return isFindPage(pathname);
  }, [pathname]);


  return (

    <Container isFindDevelopersPage={isFindDevelopersPage}>
      <HashLink to="/#" >
        <Logo />
      </HashLink>
      <a href="https://jobshash.com/" target="_blank" rel="noopener noreferrer">
        <PoweredByLogo />
      </a>
    </Container>
  );
};

export default Header;
