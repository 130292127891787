import styled from 'styled-components';
import { ThemeProps } from '@/styles/globalStyles';
import { StylesProps } from '@/utils/stylesProps';

type Props = ThemeProps & StylesProps;

export const DropdownContainer = styled.div`
  width: 100%;
  min-width: 16rem;
  height: 2.75rem;
  @media (max-width: 65rem) {
    max-width: 100%;
    margin-right: 0rem;
  }
`;

export const DropdownButton = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  box-sizing: border-box;
  padding-left: 1.5rem;
  padding-right: 0;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.white};
  border-radius: 0.5rem;
  cursor: pointer;
  font-weight: 400;
  font-size: 1rem;
  color: ${(props: Props) => props.theme.grey};
  line-height: 1.5rem;
  border: 0.0625rem solid
    ${(props: Props) => (props.isOpen ? props.theme.blue : props.theme.stroke)};
  span {
    margin: 0 0.375rem 0 0.5rem;
    min-width: 1.625rem;
    min-height: 1.625rem;
    border-radius: 0.25rem;
    font-weight: normal;
    font-size: 1rem;
    color: ${(props: Props) => props.theme.blue};
  }
  & svg {
    position: absolute;
    right: 1.125rem;
    transform: ${(props: Props) => (props.isOpen ? 'none' : 'rotate(180deg)')};
  }
  & p {
    overflow: hidden;
    width: 85%;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: left;
  }
  @media (max-width: 768px) {
    width: 100%;
    line-height: 1.3125rem;
    padding-left: 0.75rem;
    padding-right: 0;
    span {
      padding: 0.1875rem;
      margin: 0 0.5rem;
    }
    & svg {
      margin-right: 0.375rem !important;
    }
  }
`;

export const DropdownMenu = styled.ul`
  position: relative;
  z-index: 7;
  box-sizing: border-box;
  display: ${(props: Props) => (props.isOpen ? `flex` : `none`)};
  flex-direction: column;
  margin-top: 0.25rem;
  padding-left: 0;
  justify-content: space-around;
  list-style: none;
  background-color: ${(props: Props) => props.theme.white};
  color: ${(props: ThemeProps) => props.theme.black};
  width: 100%;
  box-shadow: 0px 3px 25px rgba(55, 58, 79, 0.03),
    7px 6px 22px rgba(55, 58, 79, 0.1);
  border-radius: 0.5rem;
  font-weight: 300;
  & > div {
    display: flex;
    flex-direction: column;
    max-height: 25rem;
    overflow-y: auto;
  }
  li {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin: 0.75rem 1rem;
    & button {
      padding: 0;
      text-align: start;
    }
  }
  li:first-child {
    padding-top: 0;
  }
  label {
    cursor: pointer;
    margin-left: 0.5rem;
    font-size: 1rem;
    line-height: 1.3125rem;
    width: 100%;
    &::before {
      margin-left: 0.25rem;
    }
  }
  input[type='checkbox'] {
    cursor: pointer;
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 0.25rem;
  }
  @media (max-width: 48rem) {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
`;

export const ApplyButton = styled.button`
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.3125rem;
  color: ${(props: Props) => props.theme.blue};
  background-color: transparent;
  border: none;
  cursor: pointer;
  &:hover {
    text-shadow: 0 0 0.25rem rgba(63, 114, 215, 0.3);
  }
`;

export const FooterSelect = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & div {
    margin: 0.5rem;
    overflow: hidden;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: left;
  }
  & span {
    margin: 0 0.375rem 0 0;
    font-size: 1rem;
    color: ${(props: Props) => props.theme.blue};
  }
`;

export const Separator = styled.div`
  position: absolute;
  bottom: 3.5rem;
  left: 0;
  right: 0;
  width: 100%;
  height: 0.0625rem;
  background: ${(props: ThemeProps) => props.theme.stroke};
`;

export const SearchWrapper = styled.div``;

export const EmptyBlock = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 1.5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  @media (max-width: 48rem) {
    font-size: 1rem;
  }
`;

export const Title = styled.div`
  margin: 0.75rem 1rem;
  font-weight: 600;
  font-size: 1.2rem;
  text-align: center;
`;
