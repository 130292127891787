import { getAllGeoTags } from '@/services/api';
import { deleteUnderscoreAndMakeCapitalizeFirstLetters } from '@/utils/helpers';
import { memo, useEffect, useState } from 'react';
import Dropdown from '../Dropdown';

type ITag = { name: string; type: string };
type ITags = { [key: string]: string };

const GeoDropdown = () => {
  const [regionsList, setRegionsList] = useState<ITags>({});
  const [countriesList, setCountriesList] = useState<ITags>({});

  const getGeoTags = async () => {
    try {
      const response = await getAllGeoTags();
      if (response.status === 200) {
        const regions: ITags = {};
        const countries: ITags = {};
        response.data.forEach((el: ITag) => {
          const displayName =
            el.name.length < 3
              ? el.name
              : deleteUnderscoreAndMakeCapitalizeFirstLetters(el.name);
          if (el.type === 'COUNTRY') {
            countries[el.name] = displayName;
          } else {
            regions[el.name] = displayName;
          }
        });
        setRegionsList(regions);
        setCountriesList(countries);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getGeoTags();
  }, []);

  return !!Object.keys(regionsList).length ? (
    <Dropdown
      options={regionsList}
      optionsTitle="Regions"
      secondOptionsTitle="Countries"
      secondOptions={countriesList}
      paramName="geo"
      name="Location"
      isSearchable
    />
  ) : null;
};

export default memo(GeoDropdown);
