import styled from 'styled-components';

interface IProps {
  geo?: boolean;
  isBundles?: boolean;
  isExpanded?: boolean;
}

export const TagsBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: .3rem 0 0.5rem;
  gap: 0.5rem 0.5rem;
`;

export const Tag = styled.div<IProps>`
  padding: 0.25rem 0.5rem;
  font-weight: 400;
  line-height: 1.125rem;
  font-size: 0.75rem;
  border-radius: 0.375rem;
  background-color: ${(props) =>
  props.geo ? `#F5F5FA` : `#529ee5`};
  color: ${(props) =>
  props.geo ? props.theme.deepDarkBlue : props.theme.white};
`;

export const MoreTags = styled.button<IProps>`
  padding: 0.25rem 0.5rem;
  font-weight: 400;
  line-height: 1.125rem;
  font-size: 0.75rem;
  border-radius: 0.375rem;
  background-color: ${(props) => props.isExpanded ? `#78bf5f` : `#78bf5f`};
  color: ${(props) => props.theme.white};
  border: none;
  cursor: pointer;
`;
