import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import * as styles from './index.styles';

interface IProps {
  title: string;
  info: string;
  cards: string;
  isFindDevelopersPage?: boolean;
  numberOfAudience?: string[];
}
const FindDevelopersComponet = ({
  isFindDevelopersPage,
  title,
  info,
  cards,
  numberOfAudience,
}: IProps) => {
  const { t } = useTranslation();
  return (
    <styles.Container isFindDevelopersPage={isFindDevelopersPage}>
      <styles.Content>
        <styles.InfoBlock>
          <styles.Title dangerouslySetInnerHTML={{ __html: t(`${title}`) }} />
          <styles.Info isFindDevelopersPage={isFindDevelopersPage}>
            {t(`${info}`)}
          </styles.Info>
        </styles.InfoBlock>
        {numberOfAudience && (
          <styles.CardsBlock>
            {numberOfAudience.map((card, index) => (
              <Fragment key={card}>
                <styles.Card>
                  <styles.CardTitle>
                    {card}
                  </styles.CardTitle>
                  <styles.CardSubtitle
                    isFindDevelopersPage={isFindDevelopersPage}
                  >
                    {t(`${cards}.${index + 1}`)}
                  </styles.CardSubtitle>
                </styles.Card>
                <styles.Separator isFindDevelopersPage={isFindDevelopersPage} />
              </Fragment>
            ))}
          </styles.CardsBlock>
        )}
      </styles.Content>
    </styles.Container>
  );
};

export default FindDevelopersComponet;
