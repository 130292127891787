import SearchIcon from '@/components/common/icons/SearchIcon';
import { ChangeEvent, memo } from 'react';
import { SearchContainer, SearchWrapper } from './index.styles';

interface Props {
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  isBundles?: boolean;
  isSearchable?: boolean;
}

const SearchBasic = ({ isSearchable, value, onChange, placeholder }: Props) => {
  return (
    <SearchWrapper>
      <SearchIcon />
      <SearchContainer
        value={value}
        autoFocus={isSearchable}
        placeholder={placeholder || 'Search'}
        onChange={onChange}
      />
    </SearchWrapper>
  );
};

export default memo(SearchBasic);
