import { memo } from 'react';

interface Props {
  onClick?: (e: React.SyntheticEvent) => void;
}

const Logo = ({ onClick }: Props) => (
  <svg
    width="126"
    height="24"
    viewBox="0 0 126 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
  >
    <path
      d="M9.9169 24C10.301 24 10.7627 23.8523 11.3019 23.5568L19.4903 18.8476C19.8227 18.6556 20.0886 18.4524 20.2881 18.2382C20.4875 18.024 20.6279 17.7765 20.7091 17.4958C20.7978 17.2151 20.8421 16.8717 20.8421 16.4654V7.00277C20.8421 6.34534 20.7239 5.84672 20.4875 5.50693C20.2585 5.16713 19.9372 4.99723 19.5235 4.99723C19.1394 4.99723 18.6888 5.14497 18.1717 5.44044L9.9723 10.1717C9.6325 10.3712 9.35919 10.578 9.15235 10.7922C8.95291 11.0065 8.80886 11.2539 8.72022 11.5346C8.63897 11.8153 8.59834 12.1588 8.59834 12.5651V22.0166C8.59834 22.6519 8.70914 23.1394 8.93075 23.4792C9.15974 23.8264 9.48846 24 9.9169 24ZM5.4072 21.4294C5.6362 21.4294 5.8578 21.3592 6.07202 21.2188V10.0942C6.07202 9.92428 6.0831 9.79132 6.10526 9.69529C6.13481 9.59926 6.18652 9.51801 6.26039 9.45152C6.33426 9.37765 6.44137 9.30009 6.58172 9.21884L16.2216 3.65651C16.2364 3.27978 16.1367 2.9843 15.9224 2.77008C15.7082 2.54848 15.4349 2.43767 15.1025 2.43767C14.7996 2.43767 14.4783 2.53001 14.1385 2.71468L5.41828 7.73407C5.05632 7.9409 4.7904 8.12558 4.6205 8.28809C4.4506 8.44321 4.3398 8.63527 4.28809 8.86427C4.23638 9.08587 4.21053 9.4072 4.21053 9.82825V19.7784C4.21053 20.2733 4.32133 20.6722 4.54294 20.9751C4.77193 21.2779 5.06002 21.4294 5.4072 21.4294ZM1.19668 19.0028C1.41828 19.0028 1.63989 18.9326 1.8615 18.7922V7.65651C1.8615 7.50139 1.87258 7.37211 1.89474 7.2687C1.92428 7.16528 1.97599 7.08033 2.04986 7.01385C2.12373 6.93998 2.23084 6.86242 2.37119 6.78116L12.0222 1.21884C12.0222 0.842105 11.9151 0.54663 11.7008 0.33241C11.494 0.110803 11.2281 0 10.903 0C10.578 0 10.253 0.099723 9.92798 0.299169L1.21884 5.30748C0.856879 5.51431 0.587258 5.69529 0.409972 5.85042C0.240074 6.00554 0.129271 6.1976 0.0775623 6.42659C0.0258541 6.65559 0 6.98061 0 7.40166V17.3407C0 17.8356 0.110803 18.2382 0.33241 18.5485C0.554017 18.8513 0.842105 19.0028 1.19668 19.0028Z"
      fill="#2685F0"
    />
    <text
      x="30"
      y="20"
      fill="#2685ef"
      fontFamily="'Arial', sans-serif"
      fontSize="20"
      fontWeight="bold">
      JobSpace
    </text>
  </svg>
);

export default memo(Logo);
